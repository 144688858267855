<script setup>
import router from "@/router";
import * as api from "@/api/api";
import { reactive, ref } from "vue";
import Banner from "@/components/BannerComponent.vue";

// const openLaLotto = (flag = "") => {
//   let url = "https://www.calottery.com/";
//   switch (flag) {
//     case "MM":
//       url = "https://www.megamillions.com/";
//       break;
//     case "PB":
//       url = "https://powerball.com/";
//       break;
//   }
//   window.open(url);
// };

const popups = reactive({
  datas: [],
  shows: [],
  chkboxs: [],
});

const popupIdxs = ref([]);

const getPopups = () => {
  api.popupList().then((rtns) => {
    console.log(rtns);
    let seq = 0;
    if (rtns.datas !== null) {
      for (var i = 0; i < rtns.datas.length; i++) {
        seq = rtns.datas[i].pSeq;
        popups.datas[i] = rtns.datas[i];
        popups.shows[seq] = true;
        popups.chkboxs[seq] = false;
        popupIdxs.value[seq] = seq;
      }
    }
  });
};

getPopups();

const closePopups = (seq, closeHour, flag) => {
  if (popups.chkboxs[seq] === true || flag == 1) {
    setCookie("popups[" + seq + "]", seq, closeHour, "/");
  }
  popups.shows[seq] = false;
};

const setCookie = (name, value, expiredays, path, domain, secure) => {
  var todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + expiredays);
  var curCookie =
    name +
    "=" +
    escape(value) +
    (expiredays ? "; expires=" + todayDate.toUTCString() : "") +
    (path ? "; path=" + path : "") +
    (domain ? "; domain=" + domain : "") +
    (secure ? "; secure" : "");
  document.cookie = curCookie;
};
</script>

<style lang="scss" scoped>
.popups {
  overflow: hidden;
  .popupClose {
    background-color: #000;
    color: #fff;
    font-weight: 700;
    position: relative;
    li {
      float: left;
      padding: 10px;
    }
    li:last-child {
      float: right;
      padding-right: 10px;
    }
  }
}
</style>

<template>
  <section>
    <p class="PC">메가파워와 함께하는</p>
    <p class="title PC">구매대행 이용권 결제</p>
    <p class="title MOBILE">구매대행<br />이용권 결제</p>
    <p class="cmt">* 본 서비스는 해외 제휴 구매대행 이용을 위한 결제 페이지 입니다.</p>
  </section>

  <section>
    <ul>
      <li>
        <div class="punchingBox">카드결제</div>
        <!--button
          type="button"
          class="btnGradition btnGo"
          @click="router.push('/charge/card')"
        >
          바로가기
        </button-->
      </li>
      <li>
        <div class="punchingBox">계좌이체</div>
        <button
          type="button"
          class="btnGradition btnGo"
          @click="router.push('/charge/bank')"
        >
          바로가기
        </button>
      </li>
    </ul>
  </section>

  <section class="etcMenus">
    <div class="PC">
      <ul>
        <li @click="router.push('/cs/service-chk')">서비스 이용전 꼭 확인</li>
        <li @click="router.push('/cs/notice')">공지사항</li>
        <li @click="router.push('/cs/event')">이벤트</li>
        <li @click="router.push('/cs/faq')">FAQ</li>
        <!--li @click="openLaLotto('MM')">메가밀리언 사이트</!--li>
					<li-- @click="openLaLotto('PB')">파워볼 사이트</li-->
      </ul>
    </div>

    <div class="MOBILE">
      <p @click="router.push('/cs/service-chk')">서비스 이용전 꼭 확인</p>
      <ul>
        <li @click="router.push('/cs/notice')">공지사항</li>
        <li @click="router.push('/cs/event')">이벤트</li>
        <li @click="router.push('/cs/faq')">FAQ</li>
        <!--li @click="openLaLotto('MM')">메가밀리언 사이트</!--li>
					<li-- @click="openLaLotto('PB')">파워볼 사이트</li-->
      </ul>
    </div>
  </section>

  <Banner :bnrFlag="`MI`" />

  <div
    v-for="(item, idx) in popups.datas"
    :key="idx"
    :id="`popups_` + item.pSeq"
    v-show="popups.shows[item.pSeq]"
  >
    <div
      class="popups"
      style="position: absolute; z-index: 9999"
      :style="
        `width:` +
        item.popW +
        `px;
								left:` +
        item.popX +
        `px;
								top:` +
        item.popY +
        `px;`
      "
    >
      <div
        class="popupContent"
        v-html="item.content"
        :style="`height:` + item.popH + `px`"
      ></div>
      <div class="popupClose">
        <ul>
          <li @click="closePopups(item.pSeq, item.closeHour, 1)" class="cursor">
            <input
              v-model="popups.chkboxs[item.pSeq]"
              type="checkbox"
              @click="popups.chkboxs[item.pSeq] = true"
            />
            &nbsp;
            <span>{{ item.closeHour }}시간 동안 열지 않음</span>
          </li>
          <li @click="closePopups(item.pSeq, item.closeHour, 0)" class="cursor">
            [닫기]
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
