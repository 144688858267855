import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import MainView from '@/views/MainView.vue'
import { ref } from 'vue';
const memberIdx	= ref(sessionStorage.getItem("memberIdx"));

const routes = [
	{
		path: '/',
		name: 'main',
		component: MainView,
		meta: {bgType: 'BLUE', bodyClass: 'main'}
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('@/views/TestView.vue'),
		meta: {bgType: 'BLUE', bodyClass: 'main'}
	},
	{ 
		path: '/login', 
		name: 'login',
		component: () => import('@/views/LoginView.vue'), 
		meta: {bgType: 'WHITE', btnJoin: false, bodyClass: 'login'} 
	},
	{ 
		path: '/member/id-find', 
		name: 'member-id-find',
		component: () => import('@/views/member/IdFindView.vue'), 
		meta: {bgType: 'WHITE', btnJoin: false, bodyClass: 'login'} 
	},
	{ 
		path: '/member/pw-find', 
		name: 'member-pw-find',
		component: () => import('@/views/member/PwFindView.vue'), 
		meta: {bgType: 'WHITE', btnJoin: false, bodyClass: 'login'} 
	},
	{
		path: '/join/agree',
		name: 'join-agree',
		component: () => import('@/views/join/AgreeView.vue'),
		meta: {bgType: 'WHITE', btnJoin: false, bodyClass: 'join'}
	},

	{
		path: '/join',
		name: 'join',
		component: () => import('@/views/join/JoinView.vue'),
		meta: {bgType: 'WHITE', btnJoin: false, bodyClass: 'join'}
	},

	{
		path: '/join/complete',
		name: 'join-complete',
		component: () => import('@/views/join/CompleteView.vue'),
		meta: {bgType: 'WHITE', btnJoin: false, bodyClass: 'join'}
	},

	// {
	// 	path: '/charge/card',
	// 	name: 'charge-card',
	// 	component: () => import('@/views/charge/CardView.vue'),
	// 	meta: {bgType: 'BLUE', bodyClass: 'charge', auth: true}
	// },

	{
		path: '/charge/bank',
		name: 'charge-bank',
		component: () => import('@/views/charge/BankView.vue'),
		meta: {bgType: 'BLUE', bodyClass: 'charge', auth: true}
	},

	{
		path: '/charge/card/result',
		name: 'charge-card-result',
		component: () => import('@/views/charge/card/ResultView.vue'),
		meta: {bgType: 'BLUE', bodyClass: 'charge', auth: true}
	},

	{
		path: '/charge/bank/result',
		name: 'charge-bank-result',
		component: () => import('@/views/charge/bank/ResultView.vue'),
		meta: {bgType: 'BLUE', bodyClass: 'charge', auth: true}
	},

	{
		path: '/cash/charge/list',
		name: 'cash-charge-list',
		component: () => import('@/views/cash/ChargeListView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cash', auth: true}
	},
	{
		path: '/cash/use/list',
		name: 'cash-use-list',
		component: () => import('@/views/cash/UseListView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cash', auth: true}
	},
	{
		path: '/cs/notice',
		name: 'cs-notice-list',
		component: () => import('@/views/cs/NoticeListView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cs', auth: false}
	},
	{
		path: '/cs/notice/:seq',
		name: 'cs-notice-view',
		component: () => import('@/views/cs/NoticeViewView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cs', auth: false}
	},
	{
		path: '/cs/event',
		name: 'cs-event-list',
		component: () => import('@/views/cs/EventListView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cs', auth: false}
	},
	{
		path: '/cs/event/:seq',
		name: 'cs-event-view',
		component: () => import('@/views/cs/EventViewView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cs', auth: false}
	},
	{
		path: '/cs/faq',
		name: 'cs-faq-list',
		component: () => import('@/views/cs/FaqListView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cs', auth: false}
	},
	{
		path: '/cs/service-chk',
		name: 'cs-service-chk',
		component: () => import('@/views/cs/ServiceChkView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cs', auth: false}
	},

	{
		path: '/cs/qna/write',
		name: 'cs-qna-write',
		component: () => import('@/views/cs/QnaWriteView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cs', auth: true}
	},
	{
		path: '/cs/qna',
		name: 'cs-qna-list',
		component: () => import('@/views/cs/QnaListView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'cs', auth: true}
	},

	{
		path: '/mypage/info',
		name: 'mypage-info',
		component: () => import('@/views/mypage/InfoView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'mypage', auth: true}
	},
	{
		path: '/mypage/passwd',
		name: 'mypage-passwd',
		component: () => import('@/views/mypage/PasswdView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'mypage', auth: true}
	},

	{
		path: '/mypage/out',
		name: 'mypage-out',
		component: () => import('@/views/mypage/OutView.vue'),
		meta: {bgType: 'WHITE', bodyClass: 'mypage', auth: true}
	},


]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({top: 0}),
  routes
});

router.beforeResolve((to, from, next) => {

	store.commit('setBgType', to.meta.bgType);
	if (to.meta.btnJoin === undefined) store.commit('setBtnJoin', true);
	else store.commit('setBtnJoin', false);

	store.commit('setBodyClass', to.meta.bodyClass);
	window.scrollTo(0, 0);


	if (to.meta.auth && memberIdx.value == undefined) {
		next('/login');
		return;
	}
	else {
		next();
	}

	


});

export default router
